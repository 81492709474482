import {
  ADD_MANY_APPOINTMENTS,
  APPOINTMENTS_END_LOADING,
  APPOINTMENTS_ERROR,
  APPOINTMENTS_LOADING,
  CANCEL_APPOINTMENT,
  CANCEL_MULTIPLE_APPOINTMENT,
  CHANGE_STATUS_APPOINTMENT,
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENTS,
  EDIT_APPOINTMENT,
  FILTER_APPOINTMENTS,
  FILTER_APPOINTMENTS_PRINT,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_BY_MONTH,
  GET_MY_APPOINTMENTS,
  SEED_APPOINTMENTS,
  SET_PAGINATION_COUNT,
  TODAY_APPOINTMENTS,
} from "../actionTypes";

const appointmentsReducers = (
  state = {
    AppointmentsIsLoading: true,
    AppointmentsError: null,
    appointments: [],
    appointment: {},
    appointmentPrint: [],
    message: "",
    todayAppointments: [],
    paginationCount: 0,
  },
  action,
) => {
  switch (action.type) {
    case APPOINTMENTS_LOADING:
      return { ...state, AppointmentsIsLoading: true };
    case APPOINTMENTS_END_LOADING:
      return { ...state, AppointmentsIsLoading: false };
    case APPOINTMENTS_ERROR:
      return { ...state, AppointmentsError: action.payload.message };
    case GET_APPOINTMENTS:
      return { ...state, appointments: action.payload };
    case GET_MY_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload.data,
        numberOfPages: action.payload.numberOfPages,
      };
    case SET_PAGINATION_COUNT:
      return {
        ...state,
        paginationCount: action.payload,
      };
    case FILTER_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload.data,
        numberOfPages: action.payload.numberOfPages,
      };
    case FILTER_APPOINTMENTS_PRINT:
      return {
        ...state,
        appointmentPrint: action.payload.data,
      };
    case GET_APPOINTMENTS_BY_MONTH:
      return { ...state, appointments: action.payload };
    case CREATE_APPOINTMENT:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };
    case EDIT_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((pat) =>
          pat._id === action.payload._id ? action.payload : pat,
        ),
      };
    case CHANGE_STATUS_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((pat) =>
          pat._id === action.payload._id ? action.payload : pat,
        ),
      };
    case ADD_MANY_APPOINTMENTS:
      // return { ...state, appointments:  action.payload };
      return null;
    case CANCEL_APPOINTMENT:
      return {
        ...state,

        appointments: state.appointments.map((appointment) =>
          appointment._id === action.payload.id
            ? (() => {
                appointment.status = "Annuler";
                appointment.motif = action.payload.motif;
                return appointment;
              })()
            : appointment,
        ),
      };
    case CANCEL_MULTIPLE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          action.payload.indexOf(appointment._id) !== -1
            ? (() => {
                appointment.status = "Annuler";
                return appointment;
              })()
            : appointment,
        ),
      };
    case SEED_APPOINTMENTS:
      return { ...state, appointment: action.payload };
    case TODAY_APPOINTMENTS:
      return { ...state, todayAppointments: action.payload };
    case DELETE_APPOINTMENTS:
      return {
        ...state,
        appointments: state.appointments.filter(
          (appointment) => appointment._id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default appointmentsReducers;
