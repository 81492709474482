import {
  CREATE_ETABLISSEMENT,
  DELETE_ETABLISSEMENT,
  EDIT_ETABLISSEMENT,
  ETABLISSEMENTS_END_LOADING,
  ETABLISSEMENTS_LOADING,
  GET_ETABLISSEMENTS,
} from "../actionTypes";

const etablissementsReducers = (
  state = {
    EtablissementsIsLoading: true,
    etablissements: [],
    etablissement: {},
  },
  action,
) => {
  switch (action.type) {
    case ETABLISSEMENTS_LOADING:
      return { ...state, EtablissementsIsLoading: true };
    case ETABLISSEMENTS_END_LOADING:
      return { ...state, EtablissementsIsLoading: false };
    case GET_ETABLISSEMENTS:
      return {
        ...state,
        etablissements: action.payload,
      };
    case CREATE_ETABLISSEMENT:
      return {
        ...state,
        etablissements: [...state.etablissements, action.payload],
      };
    case EDIT_ETABLISSEMENT:
      return {
        ...state,
        etablissements: state.etablissements.map((etab) =>
          etab._id === action.payload._id ? action.payload : etab,
        ),
      };
    case DELETE_ETABLISSEMENT:
      return {
        ...state,
        etablissements: state.etablissements.filter(
          (etab) => etab._id !== action.payload,
        ),
      };

    default:
      return state;
  }
};

export default etablissementsReducers;
