import {
  CREATE_SETTING,
  DELETE_SETTING,
  GET_SETTINGS,
  SEED_SETTINGS,
  SETTINGS_END_LOADING,
  SETTINGS_LOADING,
  UPDATE_SETTING,
} from "../actionTypes";

const settingsReducers = (
  state = { settingsIsLoading: true, settings: [] },
  action,
) => {
  switch (action.type) {
    case SETTINGS_LOADING:
      return { ...state, settingsIsLoading: true };
    case SETTINGS_END_LOADING:
      return { ...state, settingsIsLoading: false };
    case GET_SETTINGS:
      return { ...state, settings: action.payload };
    case SEED_SETTINGS:
      return { ...state, settings: action.payload };
    case CREATE_SETTING:
      return { ...state, settings: [...state.settings, action.payload] };
    case UPDATE_SETTING:
      return {
        ...state,
        settings: state.settings.map((setting) =>
          setting._id === action.payload._id ? action.payload : setting,
        ),
      };
    case DELETE_SETTING:
      return {
        ...state,
        settings: state.settings.filter(
          (setting) => setting._id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default settingsReducers;
