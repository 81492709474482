import {
  SEED_PATIENT,
  PATIENTS_END_LOADING,
  PATIENTS_LOADING,
  GET_PATIENTS,
  FILTER_PATIENTS,
  CREATE_PATIENT,
  EDIT_PATIENT,
  DELETE_PATIENT,
  BLOCK_PATIENT,
  GET_PATIENTS_BY_ETAB,
  GET_PATIENT,
  GET_PATIENTS_CHAT,
  PATIENT_ERROR,
} from "../actionTypes";

const patientsReducers = (
  state = {
    PatientsIsLoading: true,
    patients: [],
    patientsMap: {},
    patient: {},
    chatsByPatient: [],
    patientError: "",
    patientsPage: [],
  },
  action,
) => {
  switch (action.type) {
    case PATIENTS_LOADING:
      return { ...state, PatientsIsLoading: true };
    case PATIENTS_END_LOADING:
      return { ...state, PatientsIsLoading: false };
    case GET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
        patientsMap: action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case FILTER_PATIENTS:
      const { data, numberOfPages } = action.payload;
      return { ...state, patientsPage: data, numberOfPages };
    case GET_PATIENTS_CHAT:
      // const { data } = action.payload;
      return { ...state, chatsByPatient: action.payload.patients };
    case GET_PATIENT:
      return { ...state, patient: action.payload };
    case GET_PATIENTS_BY_ETAB:
      return {
        ...state,
        patients: action.payload,
        patientsMap: action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case CREATE_PATIENT:
      return {
        ...state,
        patients: [...state.patients, action.payload],
        patientsMap: [...state.patients, action.payload].reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case EDIT_PATIENT:
      return {
        ...state,
        patients: state.patients.map((pat) =>
          pat._id === action.payload._id ? action.payload : pat,
        ),
      };
    case PATIENT_ERROR:
      return { ...state, patientError: action.payload };
    case BLOCK_PATIENT:
      return {
        ...state,
        patients: state.patients.map((pat) =>
          pat._id === action.payload._id ? action.payload : pat,
        ),
      };
    case DELETE_PATIENT:
      return {
        ...state,
        patients: state.patients.filter((pat) => pat._id !== action.payload),
      };
    case SEED_PATIENT:
      return { ...state, patient: action.payload };
    default:
      return state;
  }
};

export default patientsReducers;
