import {
  AUTH,
  AUTH_ERROR,
  AUTH_LOADING,
  LOGOUT,
  STOP_AUTH_LOADING,
} from "../actionTypes";

const authReducer = (
  state = { authData: null, authLoading: false, authError: null },
  action,
) => {
  switch (action.type) {
    case AUTH_LOADING:
      return { ...state, authLoading: true };
    case STOP_AUTH_LOADING:
      return { ...state, authLoading: false };
    case AUTH:
      localStorage.setItem(
        "profile",
        JSON.stringify({ ...action.payload.data }),
      );
      return {
        ...state,
        authData: action.payload.data,
        authLoading: false,
        authError: null,
      };
    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null, authLoading: false, authError: null };
    case AUTH_ERROR:
      return {
        ...state,
        authLoading: false,
        authError: action.payload?.response?.data?.message,
      };
    default:
      return state;
  }
};

export default authReducer;
