import {
  EVENTS_END_LOADING,
  EVENTS_LOADING,
  FILTER_EVENTS,
} from "../actionTypes";

const eventsReducers = (
  state = { EventsIsLoading: true, events: [] },
  action,
) => {
  switch (action.type) {
    case EVENTS_LOADING:
      return { ...state, EventsIsLoading: true };
    case EVENTS_END_LOADING:
      return { ...state, EventsIsLoading: false };
    case FILTER_EVENTS:
      return {
        ...state,
        events: action.payload.data,
        numberOfPages: action.payload.numberOfPages,
      };
    default:
      return state;
  }
};

export default eventsReducers;
