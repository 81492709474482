import {
  CREATE_USER,
  DELETE_USER,
  END_LOADING_USER,
  GET_ADMINS,
  GET_USER,
  LIST_USERS,
  START_LOADING_USER,
  UPDATE_USER,
} from "../actionTypes";
// import {parse, stringify} from 'flatted';

const usersReducers = (
  state = { UsersIsLoading: true, users: [], admins: [], user: {} },
  action,
) => {
  switch (action.type) {
    case START_LOADING_USER:
      return { ...state, UsersIsLoading: true };
    case END_LOADING_USER:
      return { ...state, UsersIsLoading: false };
    case LIST_USERS:
      return { ...state, users: action.payload.data };
    case GET_ADMINS:
      return { ...state, admins: action.payload };
    case CREATE_USER:
      return { ...state, users: [...state.users, action.payload.user] };
    case UPDATE_USER:
      return { ...state, user: action.payload };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case GET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default usersReducers;
