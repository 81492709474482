import {
  DOCTORS_END_LOADING,
  DOCTORS_LOADING,
  GET_DOCTORS,
  CREATE_DOCTOR,
  EDIT_DOCTOR,
  DELETE_DOCTOR,
  GET_DOCTOR,
  GET_DOCTOR_BY_USER,
  GET_DOCTORS_BY_ETAB,
} from "../actionTypes";

const doctorsReducers = (
  state = { DoctorsIsLoading: true, doctors: [], doctorsMap: {}, doctor: {} },
  action,
) => {
  switch (action.type) {
    case DOCTORS_LOADING:
      return { ...state, DoctorsIsLoading: true };
    case DOCTORS_END_LOADING:
      return { ...state, DoctorsIsLoading: false };
    case GET_DOCTOR:
      return { ...state, doctor: action.payload };
    case GET_DOCTOR_BY_USER:
      return { ...state, doctor: action.payload };
    case GET_DOCTORS:
      return {
        ...state,
        doctors: action.payload,
        doctorsMap: action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case GET_DOCTORS_BY_ETAB:
      return {
        ...state,
        doctors: action.payload,
        doctorsMap: action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case CREATE_DOCTOR:
      return {
        ...state,
        doctors: [...state.doctors, action.payload],
        doctorsMap: [...state.doctors, action.payload].reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
      };
    case EDIT_DOCTOR:
      return {
        ...state,
        doctors: state.doctors.map((doc) =>
          doc._id === action.payload._id ? action.payload : doc,
        ),
      };
    case DELETE_DOCTOR:
      return {
        ...state,
        doctors: state.doctors.filter((doc) => doc._id !== action.payload),
      };
    default:
      return state;
  }
};

export default doctorsReducers;
