// reducers/historiqueimpression.js
import {
    GET_IMPRESSIONS,
    IMPRESSIONS_END_LOADING,
    IMPRESSIONS_LOADING,
    IMPRESSION_ERROR,
  } from "../actionTypes";
  const initialState = {
    ImpressionsIsLoading: true,
    impressions: [],
    impressionsMap: {},
    impression: {},
    impressionError: "",
    impressionsPage: [],
  };
  const impressionsReducers = (state = initialState, action) => {
    switch (action.type) {
      case IMPRESSIONS_LOADING:
        return { ...state, ImpressionsIsLoading: true };
      case IMPRESSIONS_END_LOADING:
        return { ...state, ImpressionsIsLoading: false };
      case GET_IMPRESSIONS:
        return {
          ...state,
          impressions: action.payload,
          impressionsMap: action.payload.reduce(
            (acc, curr) => ({ ...acc, [curr._id]: curr }),
            {}
          ),
        };
      case IMPRESSION_ERROR:
        return { ...state, impressionError: action.payload };
      default:
        return state;
    }
  };
  export default impressionsReducers;