import {
  SMS_END_LOADING,
  SMS_GET_TOMORROW,
  SMS_LOADING,
  SMS_LOG,
  SMS_SEND,
} from "../actionTypes";

const smsReducers = (
  state = {
    SmsIsLoading: true,
    SmsError: null,
    tomorrowApps: [],
    message: "",
    smsLog: [],
  },
  action,
) => {
  switch (action.type) {
    case SMS_LOADING:
      return { ...state, SmsIsLoading: true };
    case SMS_END_LOADING:
      return { ...state, SmsIsLoading: false };
    case SMS_GET_TOMORROW:
      return { ...state, tomorrowApps: action.payload };
    case SMS_SEND:
      return { ...state, message: action.payload.message };
    case SMS_LOG:
      return { ...state, smsLog: action.payload };
    default:
      return state;
  }
};

export default smsReducers;
