import {
  DELETE_AGENDA,
  EDIT_AGENDA,
  AGENDAS_END_LOADING,
  AGENDAS_LOADING,
  GET_AGENDAS,
  CREATE_AGENDA,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_AGENDAS_BY_IDS,
  GET_DOCTOR_DURATION,
  GET_AGENDA,
} from "../actionTypes";

const agendasReducers = (
  state = { AgendasIsLoading: true, agendas: [], agenda: {}, duration: 30 },
  action,
) => {
  switch (action.type) {
    case AGENDAS_LOADING:
      return { ...state, AgendasIsLoading: true };
    case AGENDAS_END_LOADING:
      return { ...state, AgendasIsLoading: false };
    case GET_AGENDAS:
      return { ...state, agendas: action.payload };
    case GET_AGENDA:
      return {
        ...state,
        agendas: state.agendas.map((agenda) =>
          agenda._id === action.payload?.data._id
            ? action.payload?.data
            : agenda,
        ),
      };
    case GET_AGENDAS_BY_IDS:
      return { ...state, agendas: action.payload };
    case GET_DOCTOR_DURATION:
      return { ...state, duration: action.payload };
    case CREATE_AGENDA:
      return { ...state, agendas: [...state.agendas, action.payload] };
    case EDIT_AGENDA:
      return {
        ...state,
        agendas: state.agendas.map((agenda) =>
          agenda._id === action.payload._id ? action.payload : agenda,
        ),
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        agendas: state.agendas.map((agenda) =>
          agenda._id === action.payload._id ? action.payload : agenda,
        ),
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        agendas: state.agendas.map((agenda) =>
          agenda._id === action.payload._id ? action.payload : agenda,
        ),
      };
    case DELETE_AGENDA:
      return {
        ...state,
        agendas: state.agendas.filter(
          (agenda) => agenda._id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default agendasReducers;
