import * as actionType from "../actionTypes";
export default (
  state = {
    breakObject: {},
    breakFilters: {},
    breaks: [],
    breakIsLoading: false,
  },
  action,
) => {
  const payload = action.payload;
  switch (action.type) {
    case actionType.LOADING_BREAK:
      return { ...state, breakIsLoading: true };

    case actionType.END_LOADING_BREAK:
      return { ...state, breakIsLoading: false };

    case actionType.SET_BREAK_FILTERS:
      return { ...state, breakFilters: payload };
    case actionType.SET_BREAK:
      return { ...state, breakObject: payload };

    case actionType.SET_BREAKS:
      return { ...state, breaks: payload };

    case actionType.ADD_BREAK:
      return {
        ...state,
        breaks: [payload, ...state.breaks],
      };

    case actionType.UPDATE_BREAK:
      return {
        ...state,
        breaks: state.breaks.map((document) =>
          document._id === payload._id ? payload : document,
        ),
      };

    case actionType.DELETE_BREAK:
      return {
        ...state,
        breaks: state.breaks.filter((document) => document._id !== payload),
      };

    default:
      return state;
  }
};
