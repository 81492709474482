import {
  START_LOADING_REQUEST,
  END_LOADING_REQUEST,
  GET_REQUEST,
  UPDATE_REQUEST,
} from "../actionTypes";

const requestPatientReducers = (
  state = { RequestsIsLoading: true, requests: [] },
  action,
) => {
  switch (action.type) {
    case START_LOADING_REQUEST:
      return { ...state, RequestsIsLoading: true };
    case END_LOADING_REQUEST:
      return { ...state, RequestsIsLoading: false };
    case GET_REQUEST:
      return { ...state, requests: action.payload?.data };
    case UPDATE_REQUEST:
      return {
        ...state,
        requests: state.requests.map((request) =>
          request._id === action.payload._id
            ? { ...request, ...action.payload }
            : request,
        ),
      };

    default:
      return state;
  }
};

export default requestPatientReducers;
