import { 
  GET_PRESCRIPTIONS, 
  ADD_PRESCRIPTION, 
  UPDATE_PRESCRIPTION, 
  DELETE_PRESCRIPTION, 
  PRESCRIPTION_ERROR, 
  PRESCRIPTIONS_LOADING } 
  from "../actionTypes";

const initialState = {
  prescriptions: [],
  prescriptionsIsLoading: false,
  prescriptionError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRESCRIPTIONS_LOADING:
      return { ...state, prescriptionsIsLoading: true };
    case GET_PRESCRIPTIONS:
      return { ...state, prescriptions: action.payload, prescriptionsIsLoading: false };
    case ADD_PRESCRIPTION:
      return { ...state, prescriptions: [action.payload, ...state.prescriptions], prescriptionError: null };
    case UPDATE_PRESCRIPTION:
      return {
        ...state,
        prescriptions: state.prescriptions.map((prescription) =>
          prescription._id === action.payload._id ? action.payload : prescription
        ),
        prescriptionError: null,
      };
    case DELETE_PRESCRIPTION:
      return { ...state, prescriptions: state.prescriptions.filter((prescription) => prescription._id !== action.payload) };
    case PRESCRIPTION_ERROR:
      return { ...state, prescriptionError: action.payload, prescriptionsIsLoading: false };
    default:
      return state;
  }
}
