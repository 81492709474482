import {
  CHANGE_STATUS_RESA,
  CREATE_RESA,
  DELETE_RESA,
  EDIT_MANY_RESA,
  EDIT_RESA,
  GET_RESAS,
  GET_RESAS_BY_AGENDAS,
  GET_RESAS_BY_DOCTORID,
  GET_RESAS_BY_ETABLISSEMENTID,
  GET_RESAS_BY_MONTH,
  LOCK_CALENDAR_DATE,
  RESA_END_LOADING,
  RESA_LOADING,
} from "../actionTypes";

const appointmentsReducers = (
  state = {
    ResasIsLoading: false,
    resas: [],
    resa: {},
    lockCalendarDate: null,
  },
  action,
) => {
  switch (action.type) {
    case RESA_LOADING:
      return { ...state, ResasIsLoading: true };
    case RESA_END_LOADING:
      return { ...state, ResasIsLoading: false };
    case GET_RESAS:
      return { ...state, resas: action.payload };
    case GET_RESAS_BY_MONTH:
      return { ...state, resas: action.payload };
    case GET_RESAS_BY_AGENDAS:
      return { ...state, resas: action.payload };
    case GET_RESAS_BY_DOCTORID:
      return { ...state, resas: action.payload };
    case GET_RESAS_BY_ETABLISSEMENTID:
      return { ...state, resas: action.payload };
    case CREATE_RESA:
      return { ...state, resas: [...state.resas, action.payload] };
    case EDIT_RESA:
      if (Array.isArray(action.payload)) {
        const ids = action.payload.map((resa) => resa._id);
        return {
          ...state,
          resas: state.resas.map((resa) =>
            ids.indexOf(resa._id) !== -1
              ? action.payload[ids.indexOf(resa._id)]
              : resa,
          ),
        };
      }

      return {
        ...state,
        resas: state.resas.map((resa) =>
          resa._id === action.payload._id ? action.payload : resa,
        ),
      };
    case EDIT_MANY_RESA:
      return {
        ...state,
        resas: state.resas.map((resa) =>
          resa._id === action.payload._id ? action.payload : resa,
        ),
      };
    case DELETE_RESA:
      return {
        ...state,
        resas: state.resas.filter((resa) => resa._id !== action.payload),
      };
    case CHANGE_STATUS_RESA:
      return {
        ...state,
        resas: state.resas.map((resa) => {
          if (resa._id === action.payload.id) {
            return {
              ...resa,
              status: action.payload.status,
              takedBy: action.payload.takedBy,
              overload: action.payload.overload,
            };
          } else {
            return resa;
          }
        }),
      };

    case LOCK_CALENDAR_DATE:
      return { ...state, lockCalendarDate: action.payload };
    default:
      return state;
  }
};

export default appointmentsReducers;
